// material-ui
import {
    Button,
    FormHelperText,
    Grid,
    Stack,
    TextField,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Chip,
    FormControl,
    Autocomplete,
    Box,
    IconButton,
    Input
} from '@mui/material';
import '@mui/lab';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { addMinutes, isBefore, set } from 'date-fns';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// project imports
// import AddItemPage from './AddItemPage';
import { gridSpacing } from 'store/constant';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import MainCard from 'ui-component/cards/MainCard';
import { openSnackbar } from 'store/slices/snackbar';

// // third-party
import * as yup from 'yup';
import { Formik, useFormik } from 'formik';

import axios from 'utils/axios';
import { useDispatch } from 'store';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCustomers } from 'store/slices/customer';
import { getInboundASN } from 'store/slices/inboundasn';
import vehicle from 'store/slices/vehicle';

// import { getProducts } from 'store/slices/product';

// yup validation-schema
const validationSchema = yup.object({
    customer: yup.object().required('Customer is required'),
    document_type: yup.string().required('Document Type is required'),
    creation_date: yup.string().required('Creation Date is required'),
    expected_time: yup.string().required('Expected Time is required')
    // document_no: yup.string().required('Document No. is required'),
    // document_img: yup.mixed().required('Document Image is required')
});
// ==============================|| CREATE INVOICE ||============================== //

function EditInbound() {
    const defaultDate = new Date();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [values, setValues] = useState([]);
    const [customers, setCustomer] = useState([]);
    const [locations, setLocations] = useState([]);

    const params = useParams();

    // const { customers } = useSelector((state) => state.customer);

    const { asn } = useSelector((state) => state.asn);

    const [docType, setDocType] = useState('');
    const [docPreview, setDocPreview] = useState(null);
    const MAX_FILE_SIZE_REGISTER = 2048;

    const [fields1, setFields1] = useState([{ id: 1, product: '', qty: '' }]);
    const [fieldsVehicle, setFieldsVehicle] = useState([{ id: 1, vehicle: '', vehicle_no: '' }]);
    const [fieldsDocs, setFieldsDocs] = useState([{ id: 1, no: '', document: '' }]);
    const [total, setTotal] = useState(0);
    const [validateProduct, setValidateProduct] = useState([{ id: 1, status: false, message: 'Product is Required' }]);
    const [validateQty, setValidateQty] = useState([{ id: 1, status: false, message: 'Quantity is Required' }]);
    const vehicles = [
        { id: 1, name: 'TATA ACE' },
        { id: 2, name: '14 FEET' }, // Assuming it's a standalone type, modify as needed
        { id: 3, name: '19 FEET' },
        { id: 4, name: 'TAURUS 16T (10 TYRE)' },
        { id: 5, name: 'TAURUS 21T (12 TYRE)' },
        { id: 6, name: 'CONTAINER 20 FT' },
        { id: 7, name: 'CONTAINER 32 FT SXL' },
        { id: 8, name: 'CONTAINER 32 FT MXL' },
        { id: 9, name: '20 FEET OPEN ALL SIDE (ODC)' },
        { id: 10, name: '28 FT OPEN PLATFORM' },
        { id: 11, name: '32 FT OPEN PLATFORM' },
        { id: 12, name: '40 FEET OPEN-TRAILER ODC' },
        { id: 13, name: '40 FEET CLOSED' },
        { id: 14, name: '56 FEET OPEN-TRAILER ODC' }
    ];

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    async function getCustomer() {
        const response = await axios.get(`/customer/active-customer`);
        setCustomer(response.data.data.customers);
        // setRecordCount(response.data.count);
    }
    async function getLocation() {
        const response = await axios.get(`/location/admin`);
        setLocations(response.data.data.data);
        // setRecordCount(response.data.count);
    }
    useEffect(() => {
        getCustomer();
        getLocation();
        dispatch(getInboundASN());
    }, []);
    useEffect(() => {
        setValues(asn.filter((item) => item.id === params.id));
    }, [asn]);
    const handleAddFieldVehicle = () => {
        const newFields = [...fieldsVehicle, { id: fieldsVehicle[fieldsVehicle.length - 1].id + 1, vehicle: '', vehicle_no: '' }];
        setFieldsVehicle(newFields);
    };
    const handleAddFieldDocs = () => {
        const newFields = [...fieldsDocs, { id: fieldsDocs[fieldsDocs.length - 1].id + 1, no: '', document: '' }];
        setFieldsDocs(newFields);
    };
    const handleAddField1 = () => {
        const id = Math.round(Math.random() * 100000000);
        const newFields = [...fields1, { id, product: '', qty: '' }];
        setValidateProduct([...validateProduct, { id, status: false, message: 'Product is Required' }]);
        setValidateQty([...validateQty, { id, status: false, message: 'Quantity is Required' }]);
        console.log([...validateQty, { id, status: false, message: 'Quantity is Required' }]);
        setFields1(newFields);
    };

    const getProducts = async (customerId) => {
        const response = await axios.get(`/customer/product?${customerId}`);
        if (!response.data.data.data.length) {
            setProducts([]);
            setFields1([{ id: 1, product: '', qty: '' }]);
        } else {
            // setFields1([{ id: 1, product: '', qty: '' }]);

            setProducts(response.data.data.data[0].product);
        }

        // setSelectedProducts(response.data.data.data[0].product);
    };
    const formik = useFormik({
        initialValues: {
            // appointment_no: '',
            customer: '',
            document_type: '',
            product_qty: [
                { product: '', qty: 0 }
                // Add more objects as needed
            ],
            vehicle: [{ id: '', vehicle: '', vehicle_no: '' }],
            document: [{ no: '', document: '' }],
            location: '',
            creation_date: defaultDate,
            expected_time: ''
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (values) {
                try {
                    // setOpen(true);
                    const formData = new FormData();
                    Object.keys(formik.values).map(
                        (formKey) =>
                            formKey !== 'vehicle' &&
                            formKey !== 'product_qty' &&
                            formKey !== 'document' &&
                            formKey !== 'customer' &&
                            formKey !== 'location' &&
                            formData.append(formKey, formik.values[formKey])
                    );

                    formData.append('customer', formik.values.customer.id);
                    formData.append('location', formik.values.location.id);

                    formik.values.product_qty.map((item, index) => {
                        formData.append(`product_qty[${index}][id]`, item.id);
                        formData.append(`product_qty[${index}][product]`, item.product.id || item.product);
                        formData.append(`product_qty[${index}][qty]`, item.qty);
                        return 0;
                    });
                    console.log(formik.values.vehicle);
                    formik.values.vehicle.map((item, index) => {
                        formData.append(`vehicle[${index}][id]`, item.id || '');
                        formData.append(`vehicle[${index}][vehicle]`, item.vehicle.name || item.vehicle || '');
                        if (item.status) {
                            formData.append(`vehicle[${index}][status]`, item.status);
                        }
                        formData.append(`vehicle[${index}][vehicle_no]`, item.vehicle_no || '');
                        return 0;
                    });
                    formik.values.document.map((item, index) => {
                        if (typeof item.document === 'string') {
                            formData.append(`document[${index}][no]`, item.no);

                            formData.append(`document[${index}][document]`, item.document);
                        } else {
                            formData.append(`document`, item.document);
                        }
                        return 0;
                    });
                    formData.forEach((item) => {
                        console.log('formdata', item);
                        return 0;
                    });
                    await axios.put(`/inbound/${params.id}`, formData, { 'Content-Type': 'multipart/form-data' });
                    resetForm();
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Inbound Updated successfully !',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            transition: 'SlideLeft',
                            close: true
                        })
                    );
                    navigate('/inbound');
                } catch (error) {
                    console.log(error);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: error.message,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            transition: 'SlideLeft',
                            close: true
                        })
                    );
                }
            }
        }
    });
    const handleFieldChangeDocs = (id, name, value = '') => {
        let updatedFields1;
        if (name === 'no') {
            updatedFields1 = fieldsDocs.map((field) => {
                if (field.id === id) {
                    return { ...field, [name]: value };
                }
                return { ...field, [name]: field[name] || '' };
            });
            setFieldsDocs(updatedFields1);
        }
        if (name === 'document') {
            updatedFields1 = fieldsDocs.map((field) => {
                if (field.id === id) {
                    // console.log(value, 'insideval');
                    const dotIndex = value.name.lastIndexOf('.');
                    const originalExtension = dotIndex !== -1 ? value.name.slice(dotIndex + 1) : '';
                    value = new File([value], `${field.no}.${originalExtension}`, { type: value.type, lastModified: value.lastModified });
                    // console.log(value);
                    return { ...field, [name]: value };
                }
                return { ...field, [name]: field[name] || '' };
            });
            setFieldsDocs(updatedFields1);
        }
        formik.setValues({
            ...formik.values,
            document: updatedFields1 || formik.values.document
        });
    };
    const handleFieldChangeVehicle = (id, name, value = '') => {
        let updatedFields1;
        if (name === 'vehicle') {
            updatedFields1 = fieldsVehicle.map((field) => {
                if (field.id === id) {
                    return { ...field, [name]: value };
                }
                return { ...field, [name]: field[name] || '' };
            });
            setFieldsVehicle(updatedFields1);
        }
        // }
        if (name === 'vehicle_no') {
            updatedFields1 = fieldsVehicle.map((field) => {
                if (field.id === id) {
                    // setTotal(total + parseInt(value, 10));
                    return { ...field, [name]: value };
                }
                return { ...field, [name]: field[name] || '' };
            });
            setFieldsVehicle(updatedFields1);
        }
        formik.setValues({
            ...formik.values,
            vehicle: updatedFields1 || formik.values.vehicle
        });
    };
    const handleFieldChange = (id, name, value = '') => {
        let updatedFields;
        let updatedFields1;
        if (name === 'product') {
            // console.log('product', value);
            updatedFields1 = fields1.map((field) => {
                if (field.id === id) {
                    return { ...field, [name]: value };
                }
                return { ...field, [name]: field[name] || '' };
            });
            setFields1(updatedFields1);
            // console.log(updatedFields1);
            // console.log(fields1);

            const ids = [];
            updatedFields1.map((item) => {
                if (!item.product) ids.push(item.id);
                return item;
            });
            // console.log(updatedFields1);

            setValidateProduct(
                validateProduct.map((validation) => {
                    if (ids.includes(validation.id)) return { ...validation, status: false };
                    return { ...validation, status: true };
                })
            );
        }
        if (name === 'qty') {
            // console.log('inkkjk', value, id);
            updatedFields1 = fields1.map((field) => {
                // console.log(field.id);
                if (field.id === id) {
                    return { ...field, [name]: value };
                }
                return { ...field, [name]: field[name] || '' };
            });
            const newTotal = updatedFields1.reduce((acc, field) => acc + (field.qty ? parseInt(field.qty, 10) : 0), 0);
            // console.log(updatedFields1);
            setTotal(newTotal);
            setFields1(updatedFields1);
            const ids = [];
            updatedFields1.map((item) => {
                if (!item.qty) ids.push(item.id);
                return item;
            });
            setValidateQty(
                validateQty.map((validation) => {
                    if (ids.includes(validation.id)) return { ...validation, status: false };
                    return { ...validation, status: true };
                })
            );
        }
        formik.setValues({
            // appointment_no: formik.values.appointment_no,
            ...formik.values,
            // total_qty: total,
            product_qty: updatedFields1 || formik.values.product_qty
        });
    };

    // console.log(values, 'valuesinghjkghjk');
    useEffect(() => {
        if (values.length) {
            formik.setValues({
                customer: values[0].customer,
                // customer_name: values[0].customer_name,
                document_type: values[0].document_type,
                creation_date: defaultDate,
                expected_time: values[0].expected_time,
                // document_no: values[0].document_no,
                product_qty: values[0].product,
                vehicle: values[0].vehicle,
                document: values[0].document,
                location: values[0].location
            });
            setDocPreview(values[0].document);
            setDocType('img');
            getProducts(values[0].customer.id);
            const formattedDocuments = values[0].document.map((item, index) => ({
                id: index + 1,
                no: item.no,
                document: item.document,
                file: item.file
            }));
            setFieldsDocs(formattedDocuments);
            // setFieldsVehicle(values[0].vehicle);
            const formattedVehicles = values[0].vehicle.map((veh) => ({
                id: veh.id,
                vehicle: { id: veh.id, name: veh.vehicle },
                vehicle_no: veh.vehicle_no
            }));
            setFieldsVehicle(formattedVehicles);
            const formattedProducts = values[0].product.map((item) => ({
                id: item.id,
                product: { ...item, id: item.product_id },
                qty: item.qty
            }));
            setFields1(formattedProducts);
            const validation1 = values[0].product.map((item) => ({ id: item.id, status: true, message: 'Product is Required' }));
            const validation2 = values[0].product.map((item) => ({ id: item.id, status: true, message: 'Quantity is Required' }));

            setValidateProduct(validation1);
            setValidateQty(validation2);
        }
    }, [asn]);
    const handleRemoveFieldVehicle = (id) => {
        let updatedFields = [...fieldsVehicle];
        updatedFields = updatedFields.filter((item) => item.id !== id);
        formik.setValues({
            ...formik.values,
            vehicle: updatedFields
        });
        setFieldsVehicle(updatedFields);
    };
    const handleRemoveField1 = (id) => {
        let updatedFields = [...fields1];
        updatedFields = updatedFields.filter((item) => item.id !== id);
        formik.setValues({
            ...formik.values,
            product_qty: updatedFields
        });
        setFields1(updatedFields);
    };
    const handleRemoveFieldDocs = (id) => {
        let updatedFields = [...fieldsDocs];
        updatedFields = updatedFields.filter((item) => item.id !== id);
        formik.setValues({
            ...formik.values,
            document: updatedFields
        });
        setFieldsDocs(updatedFields);
    };

    return (
        <>
            <MainCard
                title="Edit Inbound"
                secondary={
                    <Grid item>
                        <Button
                            sm={3}
                            variant="contained"
                            onClick={() => {
                                navigate('/inbound');
                            }}
                        >
                            <ChevronLeftIcon />
                            Back
                        </Button>
                    </Grid>
                }
            >
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={5}>
                            <Autocomplete
                                disablePortal
                                options={customers}
                                value={formik.values.customer || null}
                                getOptionLabel={(option) => option.customer_name}
                                size="large"
                                onBlur={() => formik.setFieldTouched('customer', formik.touched.customer)}
                                onChange={(event, newValue) => {
                                    if (newValue?.id) {
                                        formik.setFieldValue('customer', newValue);
                                        getProducts(newValue.id);
                                    } else {
                                        setProducts([]);
                                        formik.setFieldValue('customer', '');
                                    }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                    // console.log(option, value, 'option');
                                    option.id === value.id
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select a Customer"
                                        error={formik.touched.customer && Boolean(formik.errors.customer)}
                                        helperText={formik.touched.customer && formik.errors.customer}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        {option.customer_name}
                                    </Box>
                                )}
                            />
                        </Grid>
                        {/* {formik.values.country && ( */}
                        <Grid item xs={12} md={5}>
                            <FormControl fullWidth size="small" variant="outlined">
                                <InputLabel id="Select-Service-Type">Select Document Type</InputLabel>
                                <Select
                                    labeId="Select-Service-Type"
                                    id="document_type"
                                    name="document_type"
                                    label="Select Document Type"
                                    size="large"
                                    placeholder="Select Document Type"
                                    // defaultValue="Select Service Type"
                                    value={formik.values.document_type}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value="PO">PO</MenuItem>
                                    <MenuItem value="Handover Document">Handover Document</MenuItem>
                                </Select>
                                {formik.errors.document_type && <FormHelperText error>{formik.errors.document_type}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <br />
                        {formik.values.document_type && (
                            <>
                                <Grid item xs={12} md={5}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                            label="Creation Date"
                                            value={formik.values.creation_date}
                                            onChange={(newValue) => {
                                                formik.setFieldValue('creation_date', newValue);
                                            }}
                                            inputFormat="dd/MM/yyyy hh:mm a"
                                            minDate={addMinutes(new Date(), 1)}
                                            // disableTimeValidation={() => disableTimeValidation(new Date())}
                                        />
                                    </LocalizationProvider>
                                    {formik.errors.creation_date && <FormHelperText error>{formik.errors.creation_date}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                            label="Expected Time"
                                            value={formik.values.expected_time}
                                            onChange={(newValue) => {
                                                formik.setFieldValue('expected_time', newValue);
                                            }}
                                            inputFormat="dd/MM/yyyy hh:mm a"
                                            minDate={addMinutes(new Date(), 1)}
                                        />
                                    </LocalizationProvider>
                                    {formik.errors.expected_time && <FormHelperText error>{formik.errors.expected_time}</FormHelperText>}
                                </Grid>
                            </>
                        )}
                        <br />
                        {/* <Grid container spacing={gridSpacing}> */}

                        {fields1.map((field, index) => (
                            <Grid container item xs={12} spacing={gridSpacing} key={field.id}>
                                <Grid item xs={12} md={5}>
                                    <Autocomplete
                                        disablePortal
                                        id={`product_qty-${field.id}`}
                                        name={`product_qty-${field.id}`}
                                        // key={}
                                        value={field.product || ''}
                                        // options={products.filter(
                                        //     (product) => !selectedProducts.some((selected) => selected.productInfo.id === product.id)
                                        // )}
                                        options={products}
                                        getOptionLabel={(option) => option.product_name}
                                        size="large"
                                        onBlur={() => formik.setFieldTouched(`product_qty-${field.id}`, formik.touched.product)}
                                        onChange={(event, newValue) => {
                                            const check = fields1.filter((item) => item?.product?.id === newValue?.id);
                                            // console.log('check', check);
                                            if (check.length) {
                                                dispatch(
                                                    openSnackbar({
                                                        open: true,
                                                        message: 'Product  Already Selected !',
                                                        variant: 'alert',
                                                        alert: {
                                                            color: 'error'
                                                        },
                                                        transition: 'SlideLeft',
                                                        close: true
                                                    })
                                                );
                                            } else handleFieldChange(field.id, 'product', newValue || null);
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            // console.log(option, value);
                                            option.id === value.id
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select a Product"
                                                error={!validateProduct[index]?.status && validateProduct[index]?.id === field.id}
                                                helperText={!validateProduct[index]?.status && validateProduct[index]?.id === field.id}
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}>
                                                {option.product_name}
                                            </Box>
                                        )}
                                    />
                                    {!validateProduct[index]?.status && validateProduct[index]?.id === field.id && (
                                        <FormHelperText error>{validateProduct[index]?.message}</FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <FormControl fullWidth>
                                        <TextField
                                            fullWidth
                                            id={`product_qty-${field.id}`}
                                            name={`product_qty-${field.id}`}
                                            autoComplete={`product_qty-${field.id}`}
                                            value={field.qty}
                                            label="Quantity"
                                            placeholder="Enter Quantity"
                                            onChange={(e) => handleFieldChange(field.id, 'qty', e.target.value)}
                                            style={{ marginBottom: '16px' }}
                                            error={!validateQty[index]?.status && validateQty[index]?.id === field.id}
                                            InputProps={{
                                                endAdornment: (
                                                    // index === 0 ? (
                                                    <>
                                                        <IconButton onClick={handleAddField1}>
                                                            <AddCircleIcon />
                                                        </IconButton>
                                                        {fields1.length > 1 && (
                                                            <IconButton onClick={() => handleRemoveField1(field.id)}>
                                                                <RemoveCircleIcon />
                                                            </IconButton>
                                                        )}
                                                    </>
                                                )
                                            }}
                                        />
                                    </FormControl>
                                    {!validateQty[index]?.status && validateQty[index]?.id === field.id && (
                                        <FormHelperText error>{validateQty[index]?.message}</FormHelperText>
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                        {/* {fieldsVehicle.map((field) => (
                            <Grid container item xs={12} spacing={gridSpacing} key={field.id}>
                                <Grid item xs={12} md={5}>
                                    <Autocomplete
                                        disablePortal
                                        id={`vehicle_no-${field.id}`}
                                        name={`vehicle_no-${field.id}`}
                                        options={vehicles}
                                        // getOptionLabel={(option) => option.name}
                                        value={field.vehicle}
                                        getOptionLabel={(option) => option.name}
                                        size="large"
                                        onBlur={() => formik.setFieldTouched(`vehicle_no-${field.id}`, formik.touched.product)}
                                        onChange={(event, newValue) => {
                                            //
                                            handleFieldChangeVehicle(field.id, 'vehicle', newValue || null);
                                        }}
                                        isOptionEqualToValue={(option, value) => option.name === value.name}
                                        renderInput={(params) => <TextField {...params} label="Select a Vehicle" />}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}>
                                                {option.name}
                                            </Box>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <FormControl fullWidth>
                                        <TextField
                                            fullWidth
                                            id={`vehicle_no-${field.id}`}
                                            name={`vehicle_no-${field.id}`}
                                            autoComplete={`vehicle_no-${field.id}`}
                                            value={field.vehicle_no}
                                            label="Vehicle Number"
                                            placeholder="Enter Vehicle Number"
                                            onChange={(e) => handleFieldChangeVehicle(field.id, 'vehicle_no', e.target.value)}
                                            style={{ marginBottom: '16px' }}
                                            InputProps={{
                                                endAdornment: (
                                                    // index === 0 ? (
                                                    <>
                                                        <IconButton onClick={handleAddFieldVehicle}>
                                                            <AddCircleIcon />
                                                        </IconButton>
                                                        {fieldsVehicle.length > 1 && (
                                                            <IconButton onClick={() => handleRemoveFieldVehicle(field.id)}>
                                                                <RemoveCircleIcon />
                                                            </IconButton>
                                                        )}
                                                    </>
                                                )
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        ))} */}
                        {fieldsDocs.map((field) => (
                            <Grid container item xs={12} spacing={gridSpacing} key={field.id}>
                                <Grid item xs={12} md={5}>
                                    <FormControl fullWidth>
                                        <TextField
                                            fullWidth
                                            label="Enter Document Number"
                                            id={`doc_no-${field.id}`}
                                            name={`doc_no-${field.id}`}
                                            autoComplete={`doc_no-${field.id}`}
                                            value={field.no}
                                            placeholder="Enter Document Number"
                                            onChange={(e) => handleFieldChangeDocs(field.id, 'no', e.target.value)}
                                            // error={
                                            //     formik.errors.document_no && (
                                            //         <FormHelperText error>{formik.errors.document_no}</FormHelperText>
                                            //     )
                                            // }
                                        />
                                    </FormControl>
                                    {/* {formik.errors.document_no && <FormHelperText error>{formik.errors.document_no}</FormHelperText>} */}
                                </Grid>
                                <Grid item xs={6} sm={6} md={5}>
                                    <TextField
                                        fullWidth
                                        label="Upload Document (jpeg, png, pdf { MAX SIZE: 2MB})"
                                        name={`doc_no-${field.id}`}
                                        size="large"
                                        onBlur={formik.handleBlur}
                                        type="file"
                                        accept="image/jpeg, image/png, application/pdf"
                                        disabled={!field.no}
                                        // onChange={handleChange}
                                        // value={values.aadhar}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        style={{ marginBottom: '10px' }}
                                        InputProps={{
                                            endAdornment: (
                                                // index === 0 ? (
                                                <>
                                                    {/* <Input
                                                        disabled
                                                        value={field.document}
                                                        variant="outlined"
                                                        fullWidth
                                                        style={{ marginTop: '10px' }}
                                                    /> */}
                                                    {/* <span>{field?.document}</span> */}
                                                    <IconButton onClick={handleAddFieldDocs}>
                                                        <AddCircleIcon />
                                                    </IconButton>
                                                    {fieldsDocs.length > 1 && (
                                                        <IconButton onClick={() => handleRemoveFieldDocs(field.id)}>
                                                            <RemoveCircleIcon />
                                                        </IconButton>
                                                    )}
                                                </>
                                            )
                                        }}
                                        onChange={(event) => {
                                            handleFieldChangeDocs(field.id, 'document', event.currentTarget.files[0]);
                                            //     const file = event.currentTarget.files[0];
                                            //     if (
                                            //         file &&
                                            //         (file.type.startsWith('image/') || file.type === 'application/pdf') &&
                                            //         file.size < MAX_FILE_SIZE_REGISTER * 1024
                                            //     ) {
                                            //         // Valid image file
                                            //         formik.setFieldValue('document_img', file);
                                            //         if (file.type.startsWith('image/')) {
                                            //             setDocType('img');
                                            //             setDocPreview(URL.createObjectURL(file));
                                            //         } else {
                                            //             setDocType('pdf');
                                            //             setDocPreview(URL.createObjectURL(new Blob([file], { type: 'application/pdf' })));
                                            //         }
                                            //     } else {
                                            //         // Invalid file type
                                            //         setDocType('');
                                            //         event.target.value = '';
                                            //         formik.setFieldValue('document_img', null);
                                            //         setDocPreview(null);
                                            //         // Show error message or perform any other action
                                            //         let errorMsg = 'Please select a valid file type (JPEG, PNG, PDF)';
                                            //         if (file.size > MAX_FILE_SIZE_REGISTER * 1024)
                                            //             errorMsg = 'Please select a file below 2MB in size';
                                            //         // Show error message or perform any other action
                                            //         dispatch(
                                            //             openSnackbar({
                                            //                 open: true,
                                            //                 message: errorMsg,
                                            //                 variant: 'alert',
                                            //                 alert: {
                                            //                     color: 'error'
                                            //                 },
                                            //                 close: false
                                            //             })
                                            //         );
                                            //     }
                                        }}
                                    />

                                    {/* {docPreview && (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={10}
                                            md={10}
                                            sx={{ height: '400px', overflowX: 'auto', position: 'relative' }}
                                        >
                                            <IconButton
                                                aria-label="toggle remove doc/image"
                                                onClick={() => {
                                                    handleFileRemove('document_img');
                                                    formik.setFieldValue('document_img', null);
                                                }}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                sx={{
                                                    position: 'absolute!important',
                                                    top: '16px',
                                                    right: '16px',
                                                    zIndex: '999',
                                                    // backgroundColor: theme.palette.error.dark,
                                                    padding: '4px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <HighlightOffIcon />
                                            </IconButton>
                                            {docType === 'img' && (
                                                <img
                                                    src={docPreview}
                                                    alt="Doc Preview"
                                                    style={{
                                                        width: '100%',
                                                        marginTop: '10px',
                                                        height: 'auto',
                                                        // justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                />
                                            )}
                                            {docType === 'pdf' && (
                                                <iframe id="pan_cert" title="pan doc preview" width="100%" height="100%" src={docPreview} />
                                            )} */}
                                    {/* </Grid>
                                    )} */}
                                </Grid>
                            </Grid>
                        ))}

                        <Grid item xs={12} md={10}>
                            <Autocomplete
                                disablePortal
                                options={locations}
                                getOptionLabel={(option) => option.location_name}
                                size="large"
                                value={formik.values.location}
                                onBlur={() => formik.setFieldTouched('location', formik.touched.location)}
                                onChange={(event, newValue) => {
                                    if (newValue?.id) {
                                        formik.setFieldValue('location', newValue);
                                    } else {
                                        formik.setFieldValue('location', '');
                                        // setLocations([]);
                                    }
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select a Location"
                                        error={formik.touched.location && Boolean(formik.errors.location)}
                                        helperText={formik.touched.location && formik.errors.location}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        {option.location_name}
                                    </Box>
                                )}
                            />
                        </Grid>
                        <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={12}>
                            <Button variant="contained" type="submit">
                                Update Inbound
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </MainCard>
        </>
    );
}

export default EditInbound;
