// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconApps, IconArrowAutofitRight, IconArrowAutofitLeft } from '@tabler/icons';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';

const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconApps,
    IconArrowAutofitRight,
    PermIdentityIcon
};

// ==============================|| MENU ITEMS - master ||============================== //

const process = {
    id: 'process',
    // title: <FormattedMessage id="process" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'inbound',
            title: <FormattedMessage id="Inbound" />,
            type: 'collapse',
            icon: icons.IconArrowAutofitRight,
            // url: '/inbound',
            breadcrumbs: true,
            children: [
                {
                    id: 'Schedule',
                    title: <FormattedMessage id="Schedule Appointment" />,
                    type: 'item',
                    url: '/inbound',
                    icon: '',
                    breadcrumbs: false,
                    access: 'read_inbound'
                },
                {
                    id: 'gatein',
                    title: <FormattedMessage id="Gate In" />,
                    type: 'item',
                    url: '/gate-in',
                    icon: '',
                    breadcrumbs: false,
                    access: 'read_inbound'
                },
                {
                    id: 'grnlist',
                    title: <FormattedMessage id="GRN List" />,
                    type: 'item',
                    url: '/grn',
                    icon: '',
                    breadcrumbs: false,
                    access: 'read_grn'
                },
                {
                    id: 'putaway',
                    title: <FormattedMessage id="PutAway List" />,
                    type: 'item',
                    url: '/put-away',
                    icon: '',
                    breadcrumbs: false
                    // access: 'read_putaway'
                }
            ]
        }
    ]
};

export default process;
