// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard } from '@tabler/icons';
import ListIcon from '@mui/icons-material/List';

const icons = {
    IconDashboard,
    ListIcon
};

// ==============================|| MENU ITEMS - master ||============================== //

const process = {
    id: 'checklists',
    // title: <FormattedMessage id="checklists" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'checklists',
            title: <FormattedMessage id="Checklists" />,
            type: 'item',
            icon: icons.ListIcon,
            url: '/pre-stages',
            breadcrumbs: true,
            radioButtons: [
                {
                    id: 'radio1',
                    label: 'Radio 1',
                    value: 'value1'
                },
                {
                    id: 'radio2',
                    label: 'Radio 2',
                    value: 'value2'
                },
                {
                    id: 'radio3',
                    label: 'Radio 3',
                    value: 'value3'
                }
            ]
            // children: [
            //     {
            //         id: 'pre-stages',
            //         title: <FormattedMessage id="Pre-stages" />,
            //         type: 'item',
            //         url: '/pre-stages',
            //         icon: '',
            //         breadcrumbs: false
            //     },
            //     {
            //         id: 'grn-list',
            //         title: <FormattedMessage id="GRN List" />,
            //         type: 'item',
            //         url: '/admin/grn',
            //         icon: '',
            //         breadcrumbs: false,
            //         access: 'read_prestages'
            //     },
            //     {
            //         id: 'aging',
            //         title: <FormattedMessage id="Aging" />,
            //         type: 'item',
            //         url: '/aging',
            //         icon: '',
            //         breadcrumbs: false
            //     }
            // ]
        }
    ]
};

export default process;
