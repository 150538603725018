// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconApps, IconArrowAutofitLeft } from '@tabler/icons';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import InventoryIcon from '@mui/icons-material/Inventory';

const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconApps,
    IconArrowAutofitLeft,
    PermIdentityIcon
};

// ==============================|| MENU ITEMS - master ||============================== //

const inventory = {
    id: 'inventory',
    // title: <FormattedMessage id="outbound" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'inventory',
            title: <FormattedMessage id="Inventory" />,
            type: 'item',
            icon: InventoryIcon,
            url: '/inventory',
            breadcrumbs: false
        }
    ]
};

export default inventory;
