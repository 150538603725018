import dashboard from './dashboard';
import client from './client';
import permission from './permissions';
import application from './application';
import forms from './forms';
import elements from './elements';
import pages from './pages';
import utilities from './utilities';
import support from './support';
import other from './other';
import master from './master';
import yard from './yard';
import inbound from './inbound';
import checklists from './checklists';
import outbound from './outbound';
import inventory from './inventory';

// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
    items: [
        dashboard,
        client,
        master,
        yard,
        permission,
        checklists,
        inbound,
        inventory,

        application,
        forms,
        elements,
        pages,
        utilities,
        support,
        other,
        outbound
    ]
};

export default menuItems;
