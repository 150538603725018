import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Tab,
    Tabs,
    Typography,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Divider,
    Button,
    Grid
} from '@mui/material';

// assets
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import RecentActorsTwoToneIcon from '@mui/icons-material/RecentActorsTwoTone';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import AnimateButton from 'ui-component/extended/AnimateButton';
import ImageIcon from '@mui/icons-material/Image';
import Chip from 'ui-component/extended/Chip';
import axios from 'utils/axios';
import QRCode from 'react-qr-code';

// tab content
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

// ================================|| Approve Transporter UI TABS - SAMPLE ||================================ //
const Row = ({ row }) => {
    const theme = useTheme();
    const [activeDoc, setActiveDoc] = useState('');
    const [activeDocName, setActiveDocName] = useState('');
    const [firstButtonClick, setFirstButtonClick] = useState(false);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setActiveDoc(row.document[0]);
        setActiveDocName(1);
        setFirstButtonClick(true);
    }, []);

    const openDoc = (click, index) => {
        setActiveDoc(click);
        setActiveDocName(index);
        setFirstButtonClick(true);
    };
    return (
        <Grid xs={6}>
            <Grid sx={{ border: '1px solid #3c3a3a38', color: theme.palette.grey[600], margin: '10px', borderRadius: '3px' }}>
                <Tabs
                    value={value}
                    variant="scrollable"
                    onChange={handleChange}
                    sx={{
                        mb: 3,
                        '& a': {
                            minHeight: 'auto',
                            minWidth: 10,
                            py: 1.5,
                            px: 1,
                            ml: 2.2,
                            color: theme.palette.grey[600],
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        },
                        '& a.Mui-selected': {
                            color: theme.palette.primary.main
                        },
                        '& a > svg': {
                            mb: '0px !important',
                            mr: 1.1
                        }
                    }}
                >
                    <Tab
                        component={Link}
                        to="#"
                        icon={<LocalShippingIcon sx={{ fontSize: '1.3rem' }} />}
                        label="Gate In Details"
                        {...a11yProps(0)}
                    />
                    {row.document.length && (
                        <Tab
                            component={Link}
                            to="#"
                            icon={<RecentActorsTwoToneIcon sx={{ fontSize: '1.3rem' }} />}
                            label="Documents"
                            {...a11yProps(1)}
                        />
                    )}
                </Tabs>
                <TabPanel value={value} index={0}>
                    <List component="nav" aria-label="main mailbox folders">
                        <ListItemButton>
                            <ListItemIcon>
                                <PersonOutlineTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="subtitle1">Appointment No.</Typography>} />
                            <ListItemSecondaryAction>
                                <Typography variant="subtitle2" align="right">
                                    {row?.appointments?.appointment_no || ''}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                            <ListItemIcon>
                                <PersonOutlineTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="subtitle1">Customer</Typography>} />
                            <ListItemSecondaryAction>
                                <Typography variant="subtitle2" align="right">
                                    {row.customer.name || ''}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                        {/* <ListItemButton>
                            <ListItemIcon>
                                <QrCodeScannerIcon sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="subtitle1">QR</Typography>} />
                            <ListItemSecondaryAction>
                                <Typography variant="subtitle2" align="right">
                                    <QRCode title="GateIn" value={row.qr_data} bgColor="#FFFFFF" fgColor="#2f5080" size={40} />
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton> */}
                        {/* <Divider /> */}
                        <ListItemButton>
                            <ListItemIcon>
                                <VerifiedUserIcon sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="subtitle1">Status</Typography>} />
                            <ListItemSecondaryAction>
                                <Typography variant="subtitle2" align="right">
                                    {row.status === 1 ? 'Verified' : 'Pending'}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />

                        <ListItemButton>
                            <ListItemIcon>
                                <LocalShippingOutlinedIcon sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="subtitle1">Vehicle Number</Typography>} />
                            <ListItemSecondaryAction>
                                <Chip label={row.vehicle_no} sx={{ ml: 1 }} chipcolor="success" />
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                            <ListItemIcon>
                                <LocalShippingOutlinedIcon sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="subtitle1">Vehicle</Typography>} />
                            <ListItemSecondaryAction>
                                {' '}
                                <Chip label={row.vehicle} sx={{ ml: 1 }} chipcolor="success" />
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                    </List>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Typography variant="h2" align="left" sx={{ mb: 1 }}>
                        Documents
                    </Typography>
                    <Grid item xs={12} md={12} lg={12}>
                        {activeDoc && (
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container sx={{ height: '33vh', overflow: 'auto', mb: 2 }}>
                                    {activeDoc.includes('.pdf') ? (
                                        <iframe id="doc_preview" title="document preview" width="100%" height="100%" src={activeDoc} />
                                    ) : (
                                        <img width="100%" src={activeDoc || ''} alt="document" />
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Grid container direction="row" spacing={1}>
                            {row.document.map((item, index) => (
                                <Grid item key={index}>
                                    {item && (
                                        <AnimateButton
                                            scale={{
                                                hover: 1.1,
                                                tap: 0.9
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                                sx={{
                                                    boxShadow: theme.customShadows.primary,
                                                    ':hover': {
                                                        boxShadow: 'none'
                                                    },
                                                    marginLeft: 4
                                                }}
                                                onClick={() => openDoc(item, index + 1)}
                                                disabled={firstButtonClick && index + 1 === activeDocName}
                                                startIcon={<ImageIcon />}
                                            >
                                                {`View Document ${index + 1}`}
                                            </Button>
                                        </AnimateButton>
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </TabPanel>
            </Grid>
        </Grid>
    );
};
export default function ViewmasterGateIn() {
    const [gateinData, setgateData] = useState([]);
    const params = useParams();
    console.log(params);

    const gateIn = async () => {
        let str;
        if (params.appId === 'null') {
            str = `?col=id&find=${params.gateId}`;
        } else {
            str = `?col=appointment&find=${params.appId}`;
        }
        const response = await axios.get(`gate-in/view${str}`);
        setgateData(response.data.data.data);
    };
    useEffect(() => {
        gateIn();
    }, []);

    return (
        <>
            <Grid container>
                {gateinData.map((item) => (
                    <Row key={item.id} row={item} />
                ))}
            </Grid>
        </>
    );
}
