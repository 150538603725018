// material-ui
import {
    Button,
    FormHelperText,
    Grid,
    Stack,
    TextField,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Chip,
    FormControl,
    Autocomplete,
    Box,
    IconButton
} from '@mui/material';
import '@mui/lab';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { addMinutes, isBefore, set } from 'date-fns';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// project imports
// import AddItemPage from './AddItemPage';
import { gridSpacing } from 'store/constant';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import MainCard from 'ui-component/cards/MainCard';
import { openSnackbar } from 'store/slices/snackbar';

// // third-party
import * as yup from 'yup';
import { Formik, useFormik } from 'formik';

import axios from 'utils/axios';
import { useDispatch } from 'store';

import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCustomers } from 'store/slices/customer';
// import { getProducts } from 'store/slices/product';

// yup validation-schema
const validationSchema = yup.object({
    customer: yup.string().required('Customer is required'),
    location: yup.string().required('Location is required'),
    document_type: yup.string().required('Document Type is required'),
    creation_date: yup.string().required('Creation Date is required'),
    expected_time: yup.string().required('Expected Time is required')
    // document_no: yup.string().required('Document No. is required').min(3).max(50),
    // document_img: yup.mixed().required('Document Image is required')
});
// ==============================|| CREATE INVOICE ||============================== //

function CreateInbound() {
    const defaultDate = new Date();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [docType, setDocType] = useState('');
    const [docPreview, setDocPreview] = useState(null);
    const [customers, setCustomer] = useState([]);
    const [locations, setLocations] = useState([]);

    const MAX_FILE_SIZE_REGISTER = 2048;

    // const { customers } = useSelector((state) => state.customer);
    const [fields1, setFields1] = useState([{ id: 1, product: '', qty: '' }]);
    const [fieldsVehicle, setFieldsVehicle] = useState([{ id: 1, vehicle: '', vehicle_no: '' }]);
    const [fieldsDocs, setFieldsDocs] = useState([{ id: 1, no: '', document: '' }]);
    const [validateProduct, setValidateProduct] = useState([{ id: 1, status: false, message: 'Product is Required' }]);
    const [validateQty, setValidateQty] = useState([{ id: 1, status: false, message: 'Quantity is Required' }]);
    const vehicles = [
        { id: 1, name: 'TATA ACE' },
        { id: 2, name: '14 FEET' }, // Assuming it's a standalone type, modify as needed
        { id: 3, name: '19 FEET' },
        { id: 4, name: 'TAURUS 16T (10 TYRE)' },
        { id: 5, name: 'TAURUS 21T (12 TYRE)' },
        { id: 6, name: 'CONTAINER 20 FT' },
        { id: 7, name: 'CONTAINER 32 FT SXL' },
        { id: 8, name: 'CONTAINER 32 FT MXL' },
        { id: 9, name: '20 FEET OPEN ALL SIDE (ODC)' },
        { id: 10, name: '28 FT OPEN PLATFORM' },
        { id: 11, name: '32 FT OPEN PLATFORM' },
        { id: 12, name: '40 FEET OPEN-TRAILER ODC' },
        { id: 13, name: '40 FEET CLOSED' },
        { id: 14, name: '56 FEET OPEN-TRAILER ODC' }
    ];

    const handleFileRemove = (type) => {
        switch (type) {
            case 'document_img':
                setDocPreview('');
                setDocType('');
                break;
            default:
                break;
        }
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    async function getCustomer() {
        const response = await axios.get(`/customer/active-customer`);
        setCustomer(response.data.data.customers);
        // setRecordCount(response.data.count);
    }
    async function getLocation() {
        const response = await axios.get(`/location/admin`);
        setLocations(response.data.data.data);
        // setRecordCount(response.data.count);
    }
    useEffect(() => {
        getCustomer();
        getLocation();
    }, []);

    const handleAddFieldVehicle = () => {
        const newFields = [...fieldsVehicle, { id: fieldsVehicle[fieldsVehicle.length - 1].id + 1, vehicle: '', vehicle_no: '' }];
        setFieldsVehicle(newFields);
    };
    const handleAddFieldDocs = () => {
        const newFields = [...fieldsDocs, { id: fieldsDocs[fieldsDocs.length - 1].id + 1, no: '', document: '' }];
        setFieldsDocs(newFields);
    };
    const handleAddField1 = () => {
        const newFields = [...fields1, { id: fields1[fields1.length - 1].id + 1, product: '', qty: '' }];
        setValidateProduct([...validateProduct, { id: fields1[fields1.length - 1].id + 1, status: false, message: 'Product is Required' }]);
        setValidateQty([...validateQty, { id: fields1[fields1.length - 1].id + 1, status: false, message: 'Quantity is Required' }]);
        setFields1(newFields);
    };
    const getProducts = async (customerId) => {
        const response = await axios.get(`/customer/product?customerId=${customerId}`);
        if (!response.data.data.data.length) {
            setProducts([]);
            setFields1([{ id: 1, product: '', qty: '' }]);
        } else {
            setProducts(response.data.data.data[0].product);
            setFields1([{ id: 1, product: '', qty: '' }]);
        }
    };
    useEffect(() => {
        const ids = [];
        const ids1 = [];
        fields1.map((item) => {
            if (!item.product) ids.push(item.id);
            return item;
        });
        fields1.map((item) => {
            if (!item.qty) ids1.push(item.id);
            return item;
        });
        setValidateProduct(
            validateProduct.map((validation) => {
                if (ids.includes(validation.id)) return { ...validation, status: false };
                return { ...validation, status: true };
            })
        );
        setValidateQty(
            validateQty.map((validation) => {
                if (ids1.includes(validation.id)) return { ...validation, status: false };
                return { ...validation, status: true };
            })
        );
        // setTotal(0);
    }, [fields1]);
    const formik = useFormik({
        initialValues: {
            customer: '',
            document_type: '',
            product_qty: [
                { product: '', qty: 0 }
                // Add more objects as needed
            ],
            // vehicle: [{ id: '', vehicle: '', vehicle_no: '' }],
            document: [{ no: '', document: '' }],
            location: '',
            creation_date: defaultDate,
            expected_time: ''
            // document_img: ''
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (values) {
                try {
                    // formik.values.product_qty = formik.values.product_qty.map((item) => ({ ...item, product: item.product?.id }));
                    const formData = new FormData();
                    Object.keys(formik.values).map(
                        (formKey) =>
                            // formKey !== 'vehicle' &&
                            formKey !== 'product_qty' && formKey !== 'document' && formData.append(formKey, formik.values[formKey])
                    );

                    formik.values.product_qty.map((item, index) => {
                        formData.append(`product_qty[${index}][id]`, item.id);
                        formData.append(`product_qty[${index}][product]`, item.product.id);
                        formData.append(`product_qty[${index}][qty]`, item.qty);
                        return 0;
                    });
                    // formik.values.vehicle.map((item, index) => {
                    //     formData.append(`vehicle[${index}][id]`, item.id || '');
                    //     formData.append(`vehicle[${index}][vehicle]`, item.vehicle.name || '');
                    //     // formData.append(`vehicle[${index}][vehicle][id]`, item.vehicle.id);
                    //     formData.append(`vehicle[${index}][vehicle_no]`, item.vehicle_no || '');
                    //     return 0;
                    // });
                    formik.values.document.map((item, index) => {
                        formData.append(`document`, item.document);
                        return 0;
                    });

                    // );
                    // formData.append('product_qty', JSON.stringify(formik.values.product_qty));

                    // Append the document_img if it's a File input
                    // if (formik.values.document_img instanceof File) {
                    //     formData.append('document_img', formik.values.document_img);
                    // }

                    await axios.post('/inbound', formData, { 'Content-Type': 'multipart/form-data' });
                    // reset
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Inbound  Created successfully !',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            transition: 'SlideLeft',
                            close: true
                        })
                    );
                    navigate('/inbound');
                } catch (error) {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: error.message,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            transition: 'SlideLeft',
                            close: true
                        })
                    );
                }
            }
        }
    });
    const handleFieldChangeDocs = (id, name, value = '') => {
        let updatedFields1;
        if (name === 'no') {
            updatedFields1 = fieldsDocs.map((field) => {
                if (field.id === id) {
                    return { ...field, [name]: value };
                }
                return { ...field, [name]: field[name] || '' };
            });
            setFieldsDocs(updatedFields1);
        }
        if (name === 'document') {
            updatedFields1 = fieldsDocs.map((field) => {
                if (field.id === id) {
                    const dotIndex = value.name.lastIndexOf('.');
                    const originalExtension = dotIndex !== -1 ? value.name.slice(dotIndex + 1) : '';
                    value = new File([value], `${field.no}.${originalExtension}`, { type: value.type, lastModified: value.lastModified });
                    return { ...field, [name]: value };
                }
                return { ...field, [name]: field[name] || '' };
            });
            setFieldsDocs(updatedFields1);
        }
        formik.setValues({
            ...formik.values,
            document: updatedFields1 || formik.values.document
        });
    };
    const handleFieldChangeVehicle = (id, name, value = '') => {
        let updatedFields1;
        if (name === 'vehicle') {
            updatedFields1 = fieldsVehicle.map((field) => {
                if (field.id === id) {
                    return { ...field, [name]: value };
                }
                return { ...field, [name]: field[name] || '' };
            });
            setFieldsVehicle(updatedFields1);
        }
        // }
        if (name === 'vehicle_no') {
            updatedFields1 = fieldsVehicle.map((field) => {
                if (field.id === id) {
                    // setTotal(total + parseInt(value, 10));
                    return { ...field, [name]: value };
                }
                return { ...field, [name]: field[name] || '' };
            });
            setFieldsVehicle(updatedFields1);
        }
        formik.setValues({
            ...formik.values,
            vehicle: updatedFields1 || formik.values.vehicle
        });
    };
    const handleFieldChange = (id, name, value = '') => {
        let updatedFields1;
        if (name === 'product') {
            updatedFields1 = fields1.map((field) => {
                if (field.id === id) {
                    return { ...field, [name]: value };
                }
                return { ...field, [name]: field[name] || '' };
            });
            setFields1(updatedFields1);
            const ids = [];
            updatedFields1.map((item) => {
                if (!item.product) ids.push(item.id);
                return item;
            });
            setValidateProduct(
                validateProduct.map((validation) => {
                    if (ids.includes(validation.id)) return { ...validation, status: false };
                    return { ...validation, status: true };
                })
            );
        }
        // }
        if (name === 'qty') {
            const numericValue = parseFloat(value);
            updatedFields1 = fields1.map((field) => {
                if (field.id === id) {
                    // setTotal(total + parseInt(value, 10));
                    return { ...field, [name]: value };
                }
                return { ...field, [name]: field[name] || '' };
            });
            setFields1(updatedFields1);
            let ids = [];
            updatedFields1.map((item) => {
                if (!item.qty) ids.push(item.id);
                if (!parseFloat(item.qty)) ids = [];
                return item;
            });
            setValidateQty(
                validateQty.map((validation) => {
                    if (ids.includes(validation.id)) return { ...validation, status: false };
                    return { ...validation, status: true };
                })
            );
        }
        formik.setValues({
            ...formik.values,
            product_qty: updatedFields1 || formik.values.product_qty
        });
    };
    const handleRemoveField1 = (id) => {
        let updatedFields = [...fields1];
        updatedFields = updatedFields.filter((item) => item.id !== id);
        formik.setValues({
            ...formik.values,
            product_qty: updatedFields
        });
        setFields1(updatedFields);
    };
    const handleRemoveFieldVehicle = (id) => {
        let updatedFields = [...fieldsVehicle];
        updatedFields = updatedFields.filter((item) => item.id !== id);
        formik.setValues({
            ...formik.values,
            vehicle: updatedFields
        });
        setFieldsVehicle(updatedFields);
    };
    const handleRemoveFieldDocs = (id) => {
        let updatedFields = [...fieldsDocs];
        updatedFields = updatedFields.filter((item) => item.id !== id);
        formik.setValues({
            ...formik.values,
            document: updatedFields
        });
        setFieldsDocs(updatedFields);
    };

    return (
        <>
            <MainCard
                title="Create Inbound"
                secondary={
                    <Grid item>
                        <Button
                            sm={3}
                            variant="contained"
                            onClick={() => {
                                navigate('/inbound');
                            }}
                        >
                            <ChevronLeftIcon />
                            Back
                        </Button>
                    </Grid>
                }
            >
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={5}>
                            <Autocomplete
                                disablePortal
                                options={customers}
                                getOptionLabel={(option) => option.customer_name}
                                size="large"
                                onBlur={() => formik.setFieldTouched('customer', formik.touched.customer)}
                                onChange={(event, newValue) => {
                                    if (newValue?.id) {
                                        formik.setFieldValue('customer', newValue.id);
                                        getProducts(newValue.id);
                                    } else {
                                        formik.setFieldValue('customer', '');
                                        setProducts([]);
                                    }
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select a Customer"
                                        error={formik.touched.customer && Boolean(formik.errors.customer)}
                                        helperText={formik.touched.customer && formik.errors.customer}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        {option.customer_name}
                                    </Box>
                                )}
                            />
                        </Grid>
                        {/* {formik.values.country && ( */}
                        <Grid item xs={12} md={5}>
                            <FormControl fullWidth size="small" variant="outlined">
                                <InputLabel id="Select-Service-Type">Select Document Type</InputLabel>
                                <Select
                                    labeId="Select-Service-Type"
                                    id="document_type"
                                    name="document_type"
                                    label="Select Document Type"
                                    size="large"
                                    placeholder="Select Document Type"
                                    // defaultValue="Select Service Type"
                                    value={formik.values.document_type}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.errors.document_type && <FormHelperText error>{formik.errors.document_type}</FormHelperText>
                                    }
                                >
                                    <MenuItem value="PO">PO</MenuItem>
                                    <MenuItem value="Handover Document">Handover Document</MenuItem>
                                </Select>
                                {formik.errors.document_type && <FormHelperText error>{formik.errors.document_type}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <br />
                        {formik.values.document_type && (
                            <>
                                <Grid item xs={12} md={5}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                            label="Creation Date"
                                            size="large"
                                            value={formik.values.creation_date}
                                            disabled
                                            onChange={(newValue) => {
                                                formik.setFieldValue('creation_date', newValue);
                                            }}
                                            inputFormat="dd/MM/yyyy hh:mm a"
                                            minDate={addMinutes(new Date(), 1)}
                                            // disableTimeValidation={() => disableTimeValidation(new Date())}
                                        />
                                    </LocalizationProvider>
                                    {/* {formik.errors.creation_date && <FormHelperText error>{formik.errors.creation_date}</FormHelperText>} */}
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                            label="Expected Time"
                                            size="large"
                                            value={formik.values.expected_time}
                                            onChange={(newValue) => {
                                                formik.setFieldValue('expected_time', newValue);
                                            }}
                                            inputFormat="dd/MM/yyyy hh:mm a"
                                            minDate={addMinutes(new Date(), 1)}
                                        />
                                    </LocalizationProvider>
                                    {formik.errors.expected_time && <FormHelperText error>{formik.errors.expected_time}</FormHelperText>}
                                </Grid>
                            </>
                        )}
                        <br />
                        {/* <Grid container spacing={gridSpacing}> */}

                        {fields1.map((field) => (
                            <Grid container item xs={12} spacing={gridSpacing} key={field.id}>
                                <Grid item xs={12} md={5}>
                                    <Autocomplete
                                        disablePortal
                                        id={`product_qty-${field.id}`}
                                        name={`product_qty-${field.id}`}
                                        value={field.product || null}
                                        options={products}
                                        getOptionLabel={(option) => option.product_name}
                                        size="large"
                                        onBlur={() => formik.setFieldTouched(`product_qty-${field.id}`, formik.touched.product)}
                                        onChange={(event, newValue) => {
                                            const check = fields1.filter((item) => item?.product?.id === newValue?.id);
                                            if (check.length) {
                                                dispatch(
                                                    openSnackbar({
                                                        open: true,
                                                        message: 'Product  Already Selected !',
                                                        variant: 'alert',
                                                        alert: {
                                                            color: 'error'
                                                        },
                                                        transition: 'SlideLeft',
                                                        close: true
                                                    })
                                                );
                                            } else handleFieldChange(field.id, 'product', newValue || null);
                                        }}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select a Product"
                                                error={
                                                    !validateProduct[field.id - 1].status && validateProduct[field.id - 1].id === field.id
                                                }
                                                helperText={
                                                    !validateProduct[field.id - 1].status && validateProduct[field.id - 1].id === field.id
                                                }
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}>
                                                {option.product_name}
                                            </Box>
                                        )}
                                    />
                                    {!validateProduct[field.id - 1].status && validateProduct[field.id - 1].id === field.id && (
                                        <FormHelperText error>{validateProduct[field.id - 1].message}</FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <FormControl fullWidth>
                                        <TextField
                                            fullWidth
                                            id={`product_qty-${field.id}`}
                                            name={`product_qty-${field.id}`}
                                            autoComplete={`product_qty-${field.id}`}
                                            value={field.qty}
                                            label="Quantity"
                                            placeholder="Enter Quantity"
                                            onChange={(e) => handleFieldChange(field.id, 'qty', e.target.value)}
                                            style={{ marginBottom: '16px' }}
                                            error={!validateQty[field.id - 1].status && validateQty[field.id - 1].id === field.id}
                                            InputProps={{
                                                endAdornment: (
                                                    // index === 0 ? (
                                                    <>
                                                        <IconButton onClick={handleAddField1}>
                                                            <AddCircleIcon />
                                                        </IconButton>
                                                        {fields1.length > 1 && (
                                                            <IconButton onClick={() => handleRemoveField1(field.id)}>
                                                                <RemoveCircleIcon />
                                                            </IconButton>
                                                        )}
                                                    </>
                                                )
                                            }}
                                        />
                                    </FormControl>
                                    {!validateQty[field.id - 1].status && validateQty[field.id - 1].id === field.id && (
                                        <FormHelperText error>{validateQty[field.id - 1].message}</FormHelperText>
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                        {/* {fieldsVehicle.map((field) => (
                            <Grid container item xs={12} spacing={gridSpacing} key={field.id}>
                                <Grid item xs={12} md={5}>
                                    <Autocomplete
                                        disablePortal
                                        id={`vehicle_no-${field.id}`}
                                        name={`vehicle_no-${field.id}`}
                                        value={field.vehicle || null}
                                        options={vehicles}
                                        getOptionLabel={(option) => option.name}
                                        size="large"
                                        onBlur={() => formik.setFieldTouched(`vehicle_no-${field.id}`, formik.touched.product)}
                                        onChange={(event, newValue) => {
                                            //
                                            handleFieldChangeVehicle(field.id, 'vehicle', newValue || null);
                                        }}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => <TextField {...params} label="Select a Vehicle" />}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}>
                                                {option.name}
                                            </Box>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <FormControl fullWidth>
                                        <TextField
                                            fullWidth
                                            id={`vehicle_no-${field.id}`}
                                            name={`vehicle_no-${field.id}`}
                                            autoComplete={`vehicle_no-${field.id}`}
                                            value={field.vehicle_no}
                                            label="Vehicle Number"
                                            placeholder="Enter Vehicle Number"
                                            onChange={(e) => handleFieldChangeVehicle(field.id, 'vehicle_no', e.target.value)}
                                            style={{ marginBottom: '16px' }}
                                            InputProps={{
                                                endAdornment: (
                                                    // index === 0 ? (
                                                    <>
                                                        <IconButton onClick={handleAddFieldVehicle}>
                                                            <AddCircleIcon />
                                                        </IconButton>
                                                        {fieldsVehicle.length > 1 && (
                                                            <IconButton onClick={() => handleRemoveFieldVehicle(field.id)}>
                                                                <RemoveCircleIcon />
                                                            </IconButton>
                                                        )}
                                                    </>
                                                )
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        ))} */}
                        {fieldsDocs.map((field) => (
                            <Grid container item xs={12} spacing={gridSpacing} key={field.id}>
                                <Grid item xs={12} md={5}>
                                    <FormControl fullWidth>
                                        <TextField
                                            fullWidth
                                            label="Enter Document Number"
                                            id={`doc_no-${field.id}`}
                                            name={`doc_no-${field.id}`}
                                            value={field.no}
                                            placeholder="Enter Document Number"
                                            onChange={(e) => handleFieldChangeDocs(field.id, 'no', e.target.value)}
                                            // error={
                                            //     formik.errors.document_no && (
                                            //         <FormHelperText error>{formik.errors.document_no}</FormHelperText>
                                            //     )
                                            // }
                                        />
                                    </FormControl>
                                    {/* {formik.errors.document_no && <FormHelperText error>{formik.errors.document_no}</FormHelperText>} */}
                                </Grid>
                                <Grid item xs={6} sm={6} md={5}>
                                    <TextField
                                        fullWidth
                                        label="Upload Document (jpeg, png, pdf { MAX SIZE: 2MB})"
                                        name={`doc_no-${field.id}`}
                                        size="large"
                                        onBlur={formik.handleBlur}
                                        type="file"
                                        accept="image/jpeg, image/png, application/pdf"
                                        disabled={!field.no}
                                        // onChange={handleChange}
                                        // value={values.aadhar}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        style={{ marginBottom: '10px' }}
                                        InputProps={{
                                            endAdornment: (
                                                // index === 0 ? (
                                                <>
                                                    <IconButton onClick={handleAddFieldDocs}>
                                                        <AddCircleIcon />
                                                    </IconButton>
                                                    {fieldsDocs.length > 1 && (
                                                        <IconButton onClick={() => handleRemoveFieldDocs(field.id)}>
                                                            <RemoveCircleIcon />
                                                        </IconButton>
                                                    )}
                                                </>
                                            )
                                        }}
                                        onChange={(event) => {
                                            handleFieldChangeDocs(field.id, 'document', event.currentTarget.files[0]);
                                            //     const file = event.currentTarget.files[0];
                                            //     if (
                                            //         file &&
                                            //         (file.type.startsWith('image/') || file.type === 'application/pdf') &&
                                            //         file.size < MAX_FILE_SIZE_REGISTER * 1024
                                            //     ) {
                                            //         // Valid image file
                                            //         formik.setFieldValue('document_img', file);
                                            //         if (file.type.startsWith('image/')) {
                                            //             setDocType('img');
                                            //             setDocPreview(URL.createObjectURL(file));
                                            //         } else {
                                            //             setDocType('pdf');
                                            //             setDocPreview(URL.createObjectURL(new Blob([file], { type: 'application/pdf' })));
                                            //         }
                                            //     } else {
                                            //         // Invalid file type
                                            //         setDocType('');
                                            //         event.target.value = '';
                                            //         formik.setFieldValue('document_img', null);
                                            //         setDocPreview(null);
                                            //         // Show error message or perform any other action
                                            //         let errorMsg = 'Please select a valid file type (JPEG, PNG, PDF)';
                                            //         if (file.size > MAX_FILE_SIZE_REGISTER * 1024)
                                            //             errorMsg = 'Please select a file below 2MB in size';
                                            //         // Show error message or perform any other action
                                            //         dispatch(
                                            //             openSnackbar({
                                            //                 open: true,
                                            //                 message: errorMsg,
                                            //                 variant: 'alert',
                                            //                 alert: {
                                            //                     color: 'error'
                                            //                 },
                                            //                 close: false
                                            //             })
                                            //         );
                                            //     }
                                        }}
                                    />

                                    {/* {docPreview && (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={10}
                                            md={10}
                                            sx={{ height: '400px', overflowX: 'auto', position: 'relative' }}
                                        >
                                            <IconButton
                                                aria-label="toggle remove doc/image"
                                                onClick={() => {
                                                    handleFileRemove('document_img');
                                                    formik.setFieldValue('document_img', null);
                                                }}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                sx={{
                                                    position: 'absolute!important',
                                                    top: '16px',
                                                    right: '16px',
                                                    zIndex: '999',
                                                    // backgroundColor: theme.palette.error.dark,
                                                    padding: '4px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <HighlightOffIcon />
                                            </IconButton>
                                            {docType === 'img' && (
                                                <img
                                                    src={docPreview}
                                                    alt="Doc Preview"
                                                    style={{
                                                        width: '100%',
                                                        marginTop: '10px',
                                                        height: 'auto',
                                                        // justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                />
                                            )}
                                            {docType === 'pdf' && (
                                                <iframe id="pan_cert" title="pan doc preview" width="100%" height="100%" src={docPreview} />
                                            )} */}
                                    {/* </Grid>
                                    )} */}
                                </Grid>
                            </Grid>
                        ))}

                        <Grid item xs={12} md={10}>
                            <Autocomplete
                                disablePortal
                                options={locations}
                                getOptionLabel={(option) => option.location_name}
                                size="large"
                                onBlur={() => formik.setFieldTouched('location', formik.touched.location)}
                                onChange={(event, newValue) => {
                                    if (newValue?.id) {
                                        formik.setFieldValue('location', newValue.id);
                                    } else {
                                        formik.setFieldValue('location', '');
                                        // setLocations([]);
                                    }
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select a Location"
                                        error={formik.touched.location && Boolean(formik.errors.location)}
                                        helperText={formik.touched.location && formik.errors.location}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        {option.location_name}
                                    </Box>
                                )}
                            />
                        </Grid>
                        <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={12}>
                            <Button variant="contained" type="submit">
                                Add Inbound
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </MainCard>
        </>
    );
}

export default CreateInbound;
