// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconApps, IconPictureInPicture } from '@tabler/icons';
import FenceIcon from '@mui/icons-material/Fence';

const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconApps,
    IconPictureInPicture,
    FenceIcon
};

// ==============================|| MENU ITEMS - master ||============================== //

const master = {
    id: 'ui-yard',
    // title: <FormattedMessage id="forms" />,
    icon: icons.IconPictureInPicture,
    type: 'group',
    children: [
        {
            id: 'yard',
            title: <FormattedMessage id="Yard" />,
            type: 'collapse',
            icon: icons.FenceIcon,
            children: [
                {
                    id: 'yrd',
                    title: <FormattedMessage id="Yard" />,
                    type: 'item',
                    url: '/admin/yards',
                    icon: '',
                    breadcrumbs: false,
                    access: 'read_yard'
                },
                {
                    id: 'yard-section',
                    title: <FormattedMessage id="Yard Section" />,
                    type: 'item',
                    url: '/admin/yard-sections',
                    icon: '',
                    breadcrumbs: false,
                    access: 'read_yard'
                }
            ]
        }
    ]
};
export default master;
