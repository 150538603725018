// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconApps, IconArrowAutofitLeft } from '@tabler/icons';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';

const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconApps,
    IconArrowAutofitLeft,
    PermIdentityIcon
};

// ==============================|| MENU ITEMS - master ||============================== //

const outbound = {
    id: 'outbound',
    // title: <FormattedMessage id="outbound" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'outbound',
            title: <FormattedMessage id="Outbound" />,
            type: 'collapse',
            icon: icons.IconArrowAutofitLeft,
            // url: '/inbound',
            breadcrumbs: true,
            children: [
                {
                    id: 'order',
                    title: <FormattedMessage id="Order" />,
                    type: 'item',
                    url: '/order',
                    icon: '',
                    breadcrumbs: false
                    // access: 'read_order'
                },
                {
                    id: 'vehicleordermapping',
                    title: <FormattedMessage id="Order Mapping" />,
                    type: 'item',
                    url: '/order/mapping',
                    icon: '',
                    breadcrumbs: false
                    // access: 'read_vehicle_order_mapping'
                },
                {
                    id: 'pick',
                    title: <FormattedMessage id="Pick" />,
                    type: 'item',
                    url: '/pick',
                    icon: '',
                    breadcrumbs: false
                    // access: 'read_pick'
                },
                {
                    id: 'pdi',
                    title: <FormattedMessage id="PDI" />,
                    type: 'item',
                    url: '/pdi',
                    icon: '',
                    breadcrumbs: false
                    // access: 'read_pdi'
                },
                {
                    id: 'shipment',
                    title: <FormattedMessage id="Shipment" />,
                    type: 'item',
                    url: '/shipment',
                    icon: '',
                    breadcrumbs: false
                    // access: 'read_shipment'
                }
            ]
        }
    ]
};

export default outbound;
