import PropTypes from 'prop-types';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Tab,
    Tabs,
    Typography,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Divider,
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableSortLabel,
    TableRow,
    Toolbar,
    Tooltip
} from '@mui/material';

// assets
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import RecentActorsTwoToneIcon from '@mui/icons-material/RecentActorsTwoTone';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import AnimateButton from 'ui-component/extended/AnimateButton';
import ImageIcon from '@mui/icons-material/Image';
import Chip from 'ui-component/extended/Chip';
import axios from 'utils/axios';
import QRCode from 'react-qr-code';
import { visuallyHidden } from '@mui/utils';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MainCard from 'ui-component/cards/MainCard';

// tab content
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) =>
    order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// table header
const headCells = [
    {
        id: 'product',
        numeric: true,
        disablePadding: false,
        label: 'Product Name'
    },
    {
        id: 'chassis',
        numeric: true,
        disablePadding: false,
        label: 'Chassis No.'
    },
    {
        id: 'color',
        numeric: true,
        disablePadding: false,
        label: 'Color'
    },
    {
        id: 'grn_complete',
        numeric: true,
        disablePadding: false,
        label: 'Status'
    }
];

// ==============================|| TABLE - HEADER ||============================== //

function EnhancedTableHead({ onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox" sx={{ pl: 3 }}>
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts'
                        }}
                    />
                </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'left' : 'left'}
                        padding={headCell.disablePadding ? '8px' : '8px'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

// ==============================|| TABLE - HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 1,
            pr: 1,
            ...(numSelected > 0 && {
                color: (theme) => theme.palette.secondary.main
            })
        }}
    />
);

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
};

// ================================|| Approve Transporter UI TABS - SAMPLE ||================================ //
const Row = ({ row }) => {
    // TABRL

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [view, setView] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [data, setData] = React.useState([]);

    const [dense] = React.useState(false);
    const params = useParams();
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    // const [selectedValue, setSelectedValue] = React.useState([]);

    const navigate = useNavigate();
    const getVehicleDetail = async () => {
        const res = await axios.get(`/grnl?id=${params.id}`);
        console.log(res);
        setRows(res.data.data);
    };
    React.useEffect(() => {
        // getVehicleDetail();
        setRows(row.pick);
    }, []);
    const handleChildData = (data) => {
        setView(data);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            if (selected.length > 0) {
                setSelected([]);
            } else {
                const newSelectedId = rows.map((n) => n.name);
                setSelected(newSelectedId);
            }
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleAction = (row) => {
        setView(true);
        setData(row);
    };

    const theme = useTheme();
    const [activeDoc, setActiveDoc] = useState('');
    const [activeDocName, setActiveDocName] = useState('');
    const [firstButtonClick, setFirstButtonClick] = useState(false);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    console.log(row);
    useEffect(() => {
        setActiveDoc(row.document);
        setActiveDocName(1);
        setFirstButtonClick(true);
    }, []);

    const openDoc = (click, index) => {
        setActiveDoc(click);
        setActiveDocName(index);
        setFirstButtonClick(true);
    };
    return (
        <Grid xs={12}>
            <Grid sx={{ border: '1px solid #3c3a3a38', color: theme.palette.grey[600], margin: '10px', borderRadius: '3px' }}>
                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Tabs
                        value={value}
                        variant="scrollable"
                        onChange={handleChange}
                        sx={{
                            mb: 3,
                            '& a': {
                                minHeight: 'auto',
                                minWidth: 10,
                                py: 1.5,
                                px: 1,
                                ml: 2.2,
                                color: theme.palette.grey[600],
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            },
                            '& a.Mui-selected': {
                                color: theme.palette.primary.main
                            },
                            '& a > svg': {
                                mb: '0px !important',
                                mr: 1.1
                            }
                        }}
                    >
                        <Tab
                            component={Link}
                            to="#"
                            icon={<LocalShippingIcon sx={{ fontSize: '1.3rem' }} />}
                            label="Order Details"
                            {...a11yProps(0)}
                        />

                        <Tab
                            component={Link}
                            to="#"
                            icon={<RecentActorsTwoToneIcon sx={{ fontSize: '1.3rem' }} />}
                            label="Documents"
                            {...a11yProps(1)}
                        />
                    </Tabs>
                    <Grid item sx={{ p: 1 }}>
                        <Button
                            sm={3}
                            variant="contained"
                            onClick={() => {
                                navigate('/order');
                            }}
                        >
                            <ChevronLeftIcon />
                            Back
                        </Button>
                    </Grid>
                </Grid>
                <TabPanel value={value} index={0}>
                    <ListItemButton>
                        <ListItemIcon>
                            <LocalShippingOutlinedIcon sx={{ fontSize: '1.3rem' }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="subtitle1">Product</Typography>} />
                        <ListItemSecondaryAction>
                            {row.products.map((item) => (
                                <Chip
                                    label={`${item?.product.product_name}-To be Picked(${item.quantity})`}
                                    sx={{ ml: 1 }}
                                    chipcolor="success"
                                />
                            ))}
                        </ListItemSecondaryAction>
                    </ListItemButton>
                    {/* <List component="nav" aria-label="main mailbox folders">
                        <ListItemButton>
                            <ListItemIcon>
                                <VerifiedUserIcon sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="subtitle1">Order ID</Typography>} />
                            <ListItemSecondaryAction>
                                <Typography variant="subtitle2" align="right">
                                    {row?.order_no}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />

                        <ListItemButton>
                            <ListItemIcon>
                                <LocalShippingOutlinedIcon sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="subtitle1">Product</Typography>} />
                            <ListItemSecondaryAction>
                                {row.products.map((item) => (
                                    <Chip
                                        label={`${item?.product.product_name}-Quantity(${item.quantity})`}
                                        sx={{ ml: 1 }}
                                        chipcolor="success"
                                    />
                                ))}
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                    </List> */}
                    {row.pick[0].order_id && (
                        <MainCard
                            content={false}
                            title="Order Details"
                            // secondary={
                            //     <Grid item>
                            //         <Button
                            //             sm={3}
                            //             variant="contained"
                            //             onClick={() => {
                            //                 navigate('/order');
                            //             }}
                            //         >
                            //             <ChevronLeftIcon />
                            //             Back
                            //         </Button>
                            //     </Grid>
                            // }
                        >
                            {/* table */}
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                    />
                                    <TableBody>
                                        {stableSort(rows, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                /** Make sure no display bugs if row isn't an OrderData object */
                                                if (typeof row === 'number') return null;
                                                const isItemSelected = isSelected(row.name);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow
                                                        hover
                                                        // onClick={(event) => handleClick(event, row.name)}
                                                        // role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.name}
                                                        selected={isItemSelected}
                                                    >
                                                        {/* <TableCell padding="checkbox" sx={{ pl: 3 }}>
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId
                                                    }}
                                                />
                                            </TableCell> */}
                                                        <TableCell align="left">{row?.grn?.products?.product_name}</TableCell>
                                                        <TableCell align="left">{row?.grn?.chassis_no}</TableCell>
                                                        <TableCell align="left">{row?.grn?.color}</TableCell>
                                                        <TableCell align="left">
                                                            <Chip label={!row.is_picked ? 'Picked' : 'Pending'} color="success" />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {/* table data */}
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                            {/* </Paper> */}
                        </MainCard>
                    )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Typography variant="h2" align="left" sx={{ mb: 1 }}>
                        Documents
                    </Typography>
                    <Grid item xs={12} md={12} lg={12}>
                        {activeDoc && (
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container sx={{ height: '40vh', mb: 2 }}>
                                    {activeDoc.includes('.pdf') ? (
                                        <iframe id="doc_preview" title="document preview" width="100%" height="100%" src={activeDoc} />
                                    ) : (
                                        <img width="30%" height="70%" src={activeDoc || ''} alt="document" />
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </TabPanel>
            </Grid>
        </Grid>
    );
};
export default function ViewmasterGateIn() {
    const [orderData, setOrderData] = useState([]);
    const params = useParams();
    console.log(params);

    const orderGet = async () => {
        console.log('res ');
        const response = await axios.get(`order/?id=${params.id}`);
        setOrderData(response.data.data.data);
    };
    React.useEffect(() => {
        console.log('here');
        orderGet();
    }, []);
    console.log(orderData);
    return (
        <>
            {orderData.length && (
                <Grid container>
                    <Row row={orderData[0]} />
                </Grid>
            )}
        </>
    );
}
